import React, { useState } from 'react';
import { BsList } from 'react-icons/bs';
import { useLocation } from 'react-router-dom'; // For matching active routes

const Sidebar = ({ backgroundImage, logo }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation(); // Get the current route

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Close sidebar when clicking outside of it
  const closeSidebar = (e) => {
    if (isSidebarOpen && !document.getElementById('layout-menu').contains(e.target)) {
      setIsSidebarOpen(false);
    }
  };

  // Define menu items
  const menuItems = [
    { name: 'Summary Dashboard', icon: 'bx bx-home-circle', link: '/' },
    { name: 'Summarized Reviews', icon: 'bx bx-cool', link: '/reviews' },
    { name: 'Menu', icon: 'bx bx-food-menu', link: '/menu' },
    {
      name: 'Business Details',
      icon: 'bx bx-layout',
      link: '/businessDetails',
      subItems: [
        { name: 'Classification', link: '/classification' },
        { name: 'Account Details', link: '/account-details' },
        { name: 'Subscription Details', link: '/subscription-details' },
        { name: 'Payment Details', link: '/payment-details' },
      ],
    },
    { name: 'Contact US', icon: 'bx bx-support', link: '/contact' },
  ];

  return (
    <>
      <button className="btn btn-primary d-md-none" onClick={toggleSidebar}>
        <BsList />
      </button>

      {isSidebarOpen && <div className="sidebar-overlay" onClick={closeSidebar}></div>}

      <aside
        id="layout-menu"
        className={`z-[10] layout-menu menu-vertical menu bg-menu-theme ${isSidebarOpen ? 'show-sidebar' : 'offcanvas-hidden'} d-md-block`}
      >
        <div className="menu-inner-shadow"></div>
        <ul className="menu-inner py-1 bg-gradient-to-t from-[#b4507a] via-[#d6b7d1] to-[#4b254e]">
          <li className="menu-item">
            <img src={logo} alt="Logo" width="230" height="140" className="mx-auto mt-4 mb-4 rounded-lg" />
          </li>

          {/* Main Menu Items */}
          {menuItems.map((item, index) => (
            <li key={index} className={`menu-item active ${location.pathname === item.link ? 'menu-item selected' : ''}`}>
              <a href={item.link} className="menu-link text-white">
                <i className={`menu-icon tf-icons ${item.icon}`}></i>
                <div data-i18n={item.name}>{item.name}</div>
              </a>

              {/* Submenu items if available */}
              {item.subItems && (
                <ul className="menu-sub">
                  {item.subItems.map((subItem, subIndex) => (
                    <li key={subIndex} className="menu-item">
                      <a href={subItem.link} className={`menu-link ${location.pathname === subItem.link ? 'bg-red-500' : ''}`}>
                        <div data-i18n={subItem.name}>{subItem.name}</div>
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </aside>
    </>
  );
};

export default Sidebar;














// import React, { useState } from 'react';
// import { BsList } from 'react-icons/bs';

// const Sidebar = ({ backgroundImage, logo }) => {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen);
//   };

//   // Close sidebar when clicking outside of it
//   const closeSidebar = (e) => {
//     if (isSidebarOpen && !document.getElementById('layout-menu').contains(e.target)) {
//       setIsSidebarOpen(false);
//     }
//   };

//   return (
//     <>
//       <button className="btn btn-primary d-md-none" onClick={toggleSidebar}>
//         <BsList /> {/* Hamburger Icon */}
//       </button>

//       {/* Overlay to capture clicks outside the sidebar */}
//       {isSidebarOpen && <div className="sidebar-overlay" onClick={closeSidebar}></div>}

//       <aside id="layout-menu" className={`z-[9999]  layout-menu menu-vertical menu bg-menu-theme ${isSidebarOpen ? 'show-sidebar' : 'offcanvas-hidden'} d-md-block`} >
//         {/* style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', transition: 'all 0.5s ease' }} */}
//         <div className="menu-inner-shadow"></div>
//         <ul className="menu-inner py-1 bg-gradient-to-t from-[#b4507a] via-[#d6b7d1] to-[#4b254e]">
//           <li className="menu-item active">
//             <img src={logo} alt="Logo" width="230" height="140" className='mx-auto mt-4 mb-4 rounded-lg' />
//             <a href="/" className="menu-link text-white">
//               <i className="menu-icon tf-icons bx bx-home-circle"></i>
//               <div data-i18n="Analytics">Summary Dashboard</div>
//             </a>
//           </li>
//           <li className="menu-item active">
//             <a href="/reviews" className="menu-link text-white">
//               <i className="menu-icon tf-icons bx bx-cool"></i>
//               <div data-i18n="Analytics">Summarized Reviews</div>
//             </a>
//           </li>
//           <li className="menu-item active">
//             <a href="/menu" className="menu-link text-white">
//               <i className="menu-icon tf-icons bx bx-food-menu"></i>
//               <div data-i18n="Analytics">Menu</div>
//             </a>
//           </li>
//           <li className="menu-item active">
//             <a href="javascript:void(0);" className="menu-link menu-toggle text-white">
//               <i className="menu-icon tf-icons bx bx-layout"></i>
//               <div data-i18n="Layouts">Business Details</div>
//             </a>
//             <ul className="menu-sub">
//               <li className="menu-item">
//                 <a href="layouts-without-navbar.html" className="menu-link">
//                   <div data-i18n="Without navbar">Classification</div>
//                 </a>
//               </li>
//               <li className="menu-item">
//                 <a href="layouts-container.html" className="menu-link text-white">
//                   <div data-i18n="Container">Account Details</div>
//                 </a>
//               </li>
//               <li className="menu-item">
//                 <a href="layouts-container.html" className="menu-link">
//                   <div data-i18n="Container">Subscription Details</div>
//                 </a>
//               </li>
//               <li className="menu-item">
//                 <a href="layouts-container.html" className="menu-link">
//                   <div data-i18n="Container">Payment Details</div>
//                 </a>
//               </li>
//             </ul>
//           </li>
//           <li className="menu-item active">
//             <a href="index.html" className="menu-link text-white">
//               <i className="menu-icon tf-icons bx bx-support"></i>
//               <div data-i18n="Analytics">Contact US</div>
//             </a>
//           </li>
//         </ul>
//       </aside>
//     </>
//   );
// };

// export default Sidebar;