import React, { useRef, useEffect } from "react";
import * as echarts from "echarts";

const ProgressLine = () => {
  const chartContainer = useRef(null);

  useEffect(() => {
    let myChart = null;

    if (chartContainer && chartContainer.current) {
      myChart = echarts.init(chartContainer.current);

      const option = {
        xAxis: {
          type: 'category',
          data: [
            "1", "2", "3", "4", "5", "6", "7", "8", "9", "10",
            "11", "12", "13", "14", "15", "16", "17", "18", "19",
            "20", "21", "22", "23", "24", "25", "26", "27", "28",
            "29", "30",
          ],
          axisLine: { show: false }, // Hide x axis line
          axisTick: { show: false }, // Hide x axis ticks
        },
        yAxis: {
          type: 'value',
          axisLine: { show: false }, // Hide y axis line
          axisTick: { show: false }, // Hide y axis ticks
          splitLine: { show: false }, // Hide y axis grid lines
        },
        series: [
          {
            name: 'Progress',
            type: 'line',
            data: [
              30, 25, 20, 35, 29, 25, 30, 35, 40, 45,
              40, 45, 50, 51, 53, 55, 50, 55, 60, 62,
              60, 65, 60, 66, 60, 45, 40, 45, 48,
            ],
            smooth: true,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: 'rgba(25, 200, 130, 1)' },
                { offset: 1, color: 'rgba(25, 200, 130, 0.2)' },
              ]),
            },
            lineStyle: {
              color: '#9B5BA0',
              width: 2,
            },
            symbol: 'none', // This will hide the data points
          },
        ],
        tooltip: {
          trigger: 'axis',
        },
      };

      myChart.setOption(option);
    }

    // Cleanup function to be run when the component unmounts
    return () => {
      if (myChart) {
        myChart.dispose();
      }
    };
  }, []);

  return (
    <div className="container">
      <div ref={chartContainer} className="h-[600px] bg-white rounded-lg shadow-lg " />
    </div>
  );
};

export default ProgressLine;











// import React, { useRef, useEffect } from "react";
// import {
//   Chart,
//   LineController,
//   LineElement,
//   PointElement,
//   LinearScale,
//   Title,
//   CategoryScale,
// } from "chart.js";

// Chart.register(
//   LineController,
//   LineElement,
//   PointElement,
//   LinearScale,
//   Title,
//   CategoryScale
// );

// const ProgressLine = () => {
//   const chartContainer = useRef(null);

//   useEffect(() => {
//     let myChart = null;

//     if (chartContainer && chartContainer.current) {
//       const ctx = chartContainer.current.getContext("2d");

//       let gradientNegative = ctx.createLinearGradient(0, 0, 0, 300);
//       gradientNegative.addColorStop(0, "rgba(255, 80, 120, 1)");
//       gradientNegative.addColorStop(1, "rgba(255, 80, 120, .2)");

//       // Create gradients for each dataset
//       let gradientNeutral = ctx.createLinearGradient(0, 0, 0, 200);
//       gradientNeutral.addColorStop(0, "rgba(250,174,50,1)");
//       gradientNeutral.addColorStop(1, "rgba(250,174,50,.2)");

//       let gradientPositive = ctx.createLinearGradient(0, 0, 0, 200);
//       gradientPositive.addColorStop(0, "rgba(25, 200, 130, 1)");
//       gradientPositive.addColorStop(1, "rgba(25, 200, 130, .2");

//       const data = {
//         labels: [
//           "1",
//           "2",
//           "3",
//           "4",
//           "5",
//           "6",
//           "7",
//           "8",
//           "9",
//           "10",
//           "11",
//           "12",
//           "13",
//           "14",
//           "15",
//           "16",
//           "17",
//           "18",
//           "19",
//           "20",
//           "21",
//           "22",
//           "23",
//           "24",
//           "25",
//           "26",
//           "27",
//           "28",
//           "29",
//           "30",
//         ],
//         datasets: [
//           {
//             //label: 'Positive',

//             data: [
//               30, 25, 20, 35, 29, 25, 30, 35, 40, 45, 40, 45, 50, 51, 53, 55,
//               50, 55, 60, 62, 60, 65, 60, 66, 60, 45, 40, 45, 48,
//             ],
//             fill: true,
//             backgroundColor: gradientPositive,
//             borderColor: "#9B5BA0",
//             tension: 0.5,
//             pointRadius: 0 // This will hide the data points
//           },
//         ],
//       };

//       const config = {
//         type: "line",
//         data: data,
//         options: {
//           plugins: { legend: { display: false } },
//           scales: {
//             x: {
//               display: false, // This will hide x axis
//               grid: {
//                 display: false,
//               },
//             },
//             y: {
//               display: false, // This will hide y axis
//               grid: {
//                 display: false,
//               },
//             },
//           },
//         },
//       };
//       myChart = new Chart(ctx, config);
//     }

//     // Cleanup function to be run when the component unmounts
//     return () => {
//       if (myChart) {
//         myChart.destroy();
//       }
//     };
//   }, []);

//   return (
//     <div className="container">
//       <div className="row">
//         <div className="col-lg-12 mb-4 order-0">
//           <canvas ref={chartContainer} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProgressLine;
