import React from 'react'
import reviewData from '../review.json';

const Reviews = () => {
    const goodReviews = reviewData.reviews.filter(review => review.happinessScore > 5);
    const negativeReviews = reviewData.reviews.filter(review => review.happinessScore <= 5);

  return (
    <div class="container-xxl flex-grow-1 container-p-y">     
    <div class="row mb-4">
      <div class="col-md mb-4 mb-md-0">
        <div class="card">
          <h5 class="card-header">Top Positive Reviews</h5>
          <div class="card-body">
            {goodReviews.map((review, index) => ( 
                <div class="alert alert-success" role="alert">{review.text}</div>
            ))}
          </div>
        </div>
      </div>
      <div class="col-md">
        <div class="card">
          <h5 class="card-header">Top Negative Reviews</h5>
          <div class="card-body">
            {negativeReviews.map((review, index) => ( 
                <div class="alert alert-danger" role="alert">{review.text}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Reviews