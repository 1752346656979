import React from 'react';
import logo from '../assets/img/logo/fulllogo_transparent_nobuffer.png';

const Footer = () => {
  return (
    <footer className="content-footer footer bg-footer-theme">
      <div className="container-xxl d-flex flex-wrap justify-content-between align-items-center py-1">
        <div className="d-flex align-items-center justify-content-start">
          <img src={logo} alt="Company Logo" style={{ height: '75px', marginRight: '10px' }} />
          <p className="mb-0">&copy; 2024 RateTheKitchen.com</p>
        </div>
        
        {/* <div className="d-flex">
          <a href="https://themeselection.com/license/" className="footer-link me-4" target="_blank">License</a>
          <a href="https://themeselection.com/demo/sneat-bootstrap-html-admin-template/documentation/" target="_blank" className="footer-link me-4">About Us</a>
          <a href="https://github.com/themeselection/sneat-html-admin-template-free/issues" target="_blank" className="footer-link me-4">Support</a>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;