import React, { useRef, useEffect } from "react";
import {
  Chart,
  DoughnutController,
  ArcElement,
  CategoryScale,
  Title,
} from "chart.js";

Chart.register(
  DoughnutController,
  ArcElement,
  CategoryScale,
  Title
);

const SentimentProgress = (props) => {
  const chartContainer = useRef(null);

  useEffect(() => {
    let myChart = null;

    if (chartContainer && chartContainer.current) {
      const ctx = chartContainer.current.getContext('2d');

      let gradientNegative = ctx.createLinearGradient(0, 0, 0, 300);
      gradientNegative.addColorStop(0, 'rgba(255, 80, 120, 1)');
      gradientNegative.addColorStop(1, 'rgba(255, 80, 120, .2)');

      // Create gradients for each dataset
      let gradientNeutral = ctx.createLinearGradient(0, 0, 0, 200);
      gradientNeutral.addColorStop(0, 'rgba(250,174,50,1)');
      gradientNeutral.addColorStop(1, 'rgba(250,174,50,.2)');

      let gradientPositive = ctx.createLinearGradient(0, 0, 0, 200);
      gradientPositive.addColorStop(0, 'rgba(25, 200, 130, 1)');
      gradientPositive.addColorStop(1, 'rgba(25, 200, 130, .2');

      const data = {
        labels: ['Positive', 'Neutral', 'Negative'],
        datasets: [
          {
            label: 'Sentiment %',
            data: [props.positive, props.neutral, props.negative],
            backgroundColor: ['rgba(25, 200, 130, 1)', 'rgba(250, 174, 50, 1)', 'rgba(255, 80, 120, 1)'],
            hoverOffset: 20
          }
        ]
      };

      const config = {
        type: 'doughnut',
        data: data,
        options: {}
      };

      myChart = new Chart(ctx, config);
    }

    // Cleanup function to be run when the component unmounts
    return () => {
      if (myChart) {
        myChart.destroy();
      }
    };
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 mb-4 order-0 py-3">
          <canvas ref={chartContainer} />
        </div>
      </div>
    </div>
  );
};

export default SentimentProgress;



// import React, { useRef, useEffect } from "react";
// import * as echarts from "echarts";

// const SentimentProgress = (props) => {
//   const chartContainer = useRef(null);

//   useEffect(() => {
//     let myChart = null;

//     if (chartContainer && chartContainer.current) {
//       myChart = echarts.init(chartContainer.current);

//       // Define color gradients (Note: ECharts doesn't support linear gradients directly in doughnut chart as in Chart.js)
//       const option = {
//         tooltip: {
//           trigger: 'item',
//         },
//         legend: {
//           top: '5%',
//           left: 'center',
//         },
//         series: [
//           {
//             name: 'Sentiment %',
//             type: 'pie',
//             radius: ['40%', '70%'], // Inner and outer radius for doughnut shape
//             avoidLabelOverlap: false,
//             itemStyle: {
//               borderRadius: 0,
//               borderColor: '#fff',
//               borderWidth: 2,
//             },
//             label: {
//               show: false,
//               position: 'center',
//             },
//             emphasis: {
//               label: {
//                 show: true,
//                 fontSize: '20',
//                 fontWeight: 'bold',
//               },
//             },
//             labelLine: {
//               show: false,
//             },
//             data: [
//               { value: props.positive, name: 'Positive', itemStyle: { color: 'rgba(25, 200, 130, 1)' } },
//               { value: props.neutral, name: 'Neutral', itemStyle: { color: 'rgba(250, 174, 50, 1)' } },
//               { value: props.negative, name: 'Negative', itemStyle: { color: 'rgba(255, 80, 120, 1)' } },
//             ],
//           },
//         ],
//       };

//       myChart.setOption(option);
//     }

//     // Cleanup function to dispose of the chart when the component unmounts
//     return () => {
//       if (myChart) {
//         myChart.dispose();
//       }
//     };
//   }, [props.positive, props.neutral, props.negative]);

//   return (
//     <div className="container">
//       <div className="row">
//         <div className="col-lg-12 order-0">
//           <div ref={chartContainer} style={{ width: '100%', height: props.height || '300px' }} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SentimentProgress;
