import React, { useRef, useEffect } from "react";
import * as echarts from "echarts";

const SentimentTrend = () => {
  const chartContainer = useRef(null);

  useEffect(() => {
    let myChart = null;

    if (chartContainer && chartContainer.current) {
      myChart = echarts.init(chartContainer.current);

      const option = {
        xAxis: {
          type: 'category',
          data: ['5/1', '5/2', '5/3', '5/4', '5/5', '5/6', '5/7'],
          axisLine: { show: true },
          axisTick: { show: true },
        },
        yAxis: {
          type: 'value',
          axisLine: { show: true },
          axisTick: { show: true },
        },
        series: [
          {
            name: 'Negative',
            type: 'bar',
            data: [10, 20, 30, 40, 20, 10, 5],
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: 'rgba(255, 80, 120)' },
                { offset: 1, color: 'rgba(255, 80, 120)' },
              ]),
            },
            emphasis: {
              itemStyle: {
                color: 'rgb(255, 99, 132)',
              },
            },
          },
          {
            name: 'Neutral',
            type: 'bar',
            data: [65, 59, 80, 81, 56, 55, 40],
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: 'rgba(250,174,50)' },
                { offset: 1, color: 'rgba(250,174,50)' },
              ]),
            },
            emphasis: {
              itemStyle: {
                color: 'rgb(250,174,50)',
              },
            },
          },
          {
            name: 'Positive',
            type: 'bar',
            data: [35, 40, 60, 67, 40, 45, 30],
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: 'rgba(25, 200, 130)' },
                { offset: 1, color: 'rgba(25, 200, 130)' },
              ]),
            },
            emphasis: {
              itemStyle: {
                color: 'rgb(75, 192, 192)',
              },
            },
          },
        ],
        tooltip: {
          trigger: 'item',
        },
      };

      myChart.setOption(option);
    }

    // Cleanup function to be run when the component unmounts
    return () => {
      if (myChart) {
        myChart.dispose();
      }
    };
  }, []);

  return (
    <div className="container">
      <div ref={chartContainer} className="h-[600px] bg-white rounded-lg shadow-lg mt-20" />
    </div>
  );
};

export default SentimentTrend;










// import React, { useRef, useEffect } from "react";
// import {
//   Chart,
//   BarController,
//   BarElement,
//   PointElement,
//   LinearScale,
//   Title,
//   CategoryScale,
// } from "chart.js";

// Chart.register(
//   BarController,
//   BarElement,
//   PointElement,
//   LinearScale,
//   Title,
//   CategoryScale
// );

// const SentimentTrend = () => {
//   const chartContainer = useRef(null);

//   useEffect(() => {
//     let myChart = null;
  
//     if (chartContainer && chartContainer.current) {
//       const ctx = chartContainer.current.getContext('2d');

//       let gradientNegative = ctx.createLinearGradient(0, 0, 0, 300);
//       gradientNegative.addColorStop(0, 'rgba(255, 80, 120, 1)');
//       gradientNegative.addColorStop(1, 'rgba(255, 80, 120, .2)');
  
      
//       // Create gradients for each dataset
//       let gradientNeutral = ctx.createLinearGradient(0, 0, 0, 200);
//       gradientNeutral.addColorStop(0, 'rgba(250,174,50,1)');   
//       gradientNeutral.addColorStop(1, 'rgba(250,174,50,.2)');
      
//       let gradientPositive = ctx.createLinearGradient(0, 0, 0, 200);
//       gradientPositive.addColorStop(0, 'rgba(25, 200, 130, 1)');
//       gradientPositive.addColorStop(1, 'rgba(25, 200, 130, .2');
      
      
//       const data = {
//         labels: ['5/1', '5/2', '5/3', '5/4', '5/5', '5/6', '5/7'],
//         datasets: [
//           {
//             label: 'Negative',
//             data: [10, 20, 30, 40, 20, 10, 5],
//             fill: true,
//             backgroundColor: gradientNegative,
//             borderColor: 'rgb(255, 99, 132)',
//             tension: 0.1
//           },
          
//           {
//           label: 'Neutral',
//           data: [65, 59, 80, 81, 56, 55, 40],
//           fill: true,

//           backgroundColor: gradientNeutral,
//           borderColor: 'rgb(250,174,50)',
//           tension: 0.1
//         }
//         ,
//         {
//           label: 'Positive',
//           data: [35, 40, 60, 67, 40, 45, 30],
//           fill: true,
//           backgroundColor: gradientPositive,
//           borderColor: 'rgb(75, 192, 192)',
//           tension: 0.1
//         },
  
//     ]
//       };
  
//       const config = {
//         type: 'bar',
//         data: data,
//         options: {}
//       };
  
//       myChart = new Chart(ctx, config);
//     }
  
//     // Cleanup function to be run when the component unmounts
//     return () => {
//       if (myChart) {
//         myChart.destroy();
//       }
//     };
//   }, []);

//   return (
//     <div className="container">
//       <div className="row">
//         <div className="col-lg-12 mb-4 order-0">
//           <canvas ref={chartContainer} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SentimentTrend;
