import React, { useState, useRef, useEffect } from 'react';
import user from "../assets/img/avatars/7.png";
import { MdLogout } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import logo from '../bgRemoveLogo.png';
import { RiLockPasswordFill } from 'react-icons/ri';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
    const [currentPass, setCurrentPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const modalRef = useRef();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const navigate = useNavigate();

    const closeModal = () => {
        setIsModalOpen(false);
        setIsChangePasswordModalOpen(false);
        setCurrentPass('');
        setNewPass('');
        setConfirmPass('');
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                closeModal();
            }
        };

        if (isModalOpen || isChangePasswordModalOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isModalOpen, isChangePasswordModalOpen]);

    const handleChangePassword = (e) => {
        e.preventDefault();
        // Implement password change logic here
        closeModal();
    };

    const handleNavigate = (path) => {
        navigate(path);
        setIsOpen(false);
    };

    return (
        <header className="fixed z-10 flex justify-between items-center py-[11px] bg-gradient-to-t from-[#623D63] to-[#452347] text-white">
            <div className="text-xl font-bold">
                <img src={logo} alt="logo" className="w-12 h-10 rounded-lg" />
            </div>
            <div>
                <div className="hidden md:flex space-x-4">
                    <div className='flex justify-end gap-7 mr-6 items-center'>
                        <button className='bg-[#F7F0FA] flex items-center gap-2 text-[#4B284D] font-semibold rounded-[5px] px-2 py-1'>
                            <MdLogout size={23} color='#4B284D' />
                            Logout
                        </button>
                        <div onClick={() => setIsModalOpen(true)} className='cursor-pointer'>
                            <img src={user} alt="user" className='w-11 h-11 rounded-full' />
                        </div>
                    </div>
                </div>
                <div className="md:hidden flex items-center">
                    <div className='flex justify-end gap-7 mr-6 items-center'>
                        <button className='bg-[#F7F0FA] flex items-center gap-2 text-[#4B284D] font-semibold rounded-[5px] px-2 py-1'>
                            <MdLogout size={23} color='#4B284D' />
                            Logout
                        </button>
                        <div onClick={() => setIsModalOpen(true)} className='cursor-pointer'>
                            <img src={user} alt="user" className='w-11 h-11 rounded-full' />
                        </div>
                    </div>
                    <button onClick={toggleMenu} className="focus:outline-none">
                        <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            {isOpen ? (
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            ) : (
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                            )}
                        </svg>
                    </button>
                </div>
                {isOpen && (
                    <div className="absolute top-16 left-0 w-full bg-gray-800 md:hidden py-4">
                        <p onClick={() => handleNavigate("/")} className='text-white no-underline cursor-pointer'>Summary Dashboard</p>
                        <p onClick={() => handleNavigate("/reviews")} className='text-white no-underline cursor-pointer'>Summarized Reviews</p>
                        <p onClick={() => handleNavigate("/menu")} className='text-white no-underline cursor-pointer'>Menu</p>
                        <p onClick={() => handleNavigate("/business-details")} className='text-white no-underline cursor-pointer'>Business Details</p>
                        <p onClick={() => handleNavigate("/contact-us")} className='text-white no-underline cursor-pointer'>Contact US</p>
                    </div>
                )}
            </div>

            {/* Modal for user info */}
            {isModalOpen && (
                <div className="fixed right-0 flex justify-end pr-5 mt-52 md:pr-5">
                    <div
                        ref={modalRef}
                        className="bg-white shadow-[0px_1px_4px_0px_rgba(188,186,184.25)] rounded-2xl w-64 mt-2 text-center"
                    >
                        <div className="flex items-center justify-start py-3 ps-3 gap-3 border-b-2 border-stone-300 ">
                            <div>
                                <img
                                    src={user}
                                    alt="userProfileHeader"
                                    className="h-[50px] w-[50px] rounded-full"
                                />
                            </div>
                            <div className="text-start text-black">
                                <p className="text-[17px] font-semibold m-0">Review Smart</p>
                                <p className="text-sm font-medium text-zinc-600 m-0">Admin</p>
                            </div>
                        </div>
                        <div className="ps-7 text-zinc-600 pb-3">
                            <button
                                onClick={() => setIsChangePasswordModalOpen(true)}
                                className="flex items-center justify-start gap-3 pt-2"
                            >
                                <RiLockPasswordFill size={20} />
                                <p className="text-lg font-medium m-0">Change password</p>
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Change Password Modal */}
            {isChangePasswordModalOpen && (
                <div className=" fixed inset-0 flex items-center justify-center text-black text-start bg-[#0000001f]">
                    <div className="bg-white rounded-lg p-6 w-80">
                        <h2 className="text-lg font-semibold mb-4">Change Password</h2>
                        <form onSubmit={handleChangePassword}>
                            <div className="mb-4">
                                <label className="block text-sm mb-1" htmlFor="currentPass">Current Password</label>
                                <input
                                    type="password"
                                    id="currentPass"
                                    value={currentPass}
                                    onChange={(e) => setCurrentPass(e.target.value)}
                                    className="border rounded w-full px-2 py-1"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm mb-1" htmlFor="newPass">New Password</label>
                                <input
                                    type="password"
                                    id="newPass"
                                    value={newPass}
                                    onChange={(e) => setNewPass(e.target.value)}
                                    className="border rounded w-full px-2 py-1"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm mb-1" htmlFor="confirmPass">Confirm Password</label>
                                <input
                                    type="password"
                                    id="confirmPass"
                                    value={confirmPass}
                                    onChange={(e) => setConfirmPass(e.target.value)}
                                    className="border rounded w-full px-2 py-1"
                                    required
                                />
                            </div>
                            <div className="flex justify-between">
                                <button type="button" onClick={closeModal} className="bg-gray-300 px-3 py-1 rounded">Cancel</button>
                                <button type="submit" className="bg-blue-500 text-white px-3 py-1 rounded">Change</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </header>
    );
};

export default Header;
