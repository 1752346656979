
import React from 'react'

const ReviewByCategory = () => {
  return (
    // <!-- Order Statistics -->
    <div className="container flex-grow-1 container-p-y">
      <div className="row">
    <div className="col-12 col-lg-4 col-xl-4 order-0 mb-4">
      <div className="card h-100">
        <div className="card-header d-flex align-items-center justify-content-between pb-0">
          <div className="card-title mb-0">
            <h5 className="m-0 me-2">Review Statistics Category</h5>
            <small className="text-muted">98 Total Reviews Today</small>
          </div>
          <div className="dropdown">
            <button
              className="btn p-0"
              type="button"
              id="orederStatistics"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="bx bx-dots-vertical-rounded"></i>
            </button>
            <div className="dropdown-menu dropdown-menu-end" aria-labelledby="orederStatistics">
              <a className="dropdown-item" href="javascript:void(0);">Select All</a>
              <a className="dropdown-item" href="javascript:void(0);">Refresh</a>
              <a className="dropdown-item" href="javascript:void(0);">Share</a>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex flex-column align-items-center gap-1">
              <h2 className="mb-2">857</h2>
              <span>Total Reviews for Month</span>
            </div>
            <div id="orderStatisticsChart"></div>
          </div>
          <ul className="p-0 m-0">
            <li className="d-flex mb-4 pb-1">
              <div className="avatar flex-shrink-0 me-3">
                <span className="avatar-initial rounded bg-label-primary"
                  ><i className="bx bx-mobile-alt"></i
                ></span>
              </div>
              <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                <div className="me-2">
                  <h6 className="mb-0">Starters</h6>
                  <small className="text-muted">xxx, xx, xxxx</small>
                </div>
                <div className="user-progress">
                  <small className="fw-semibold">45</small>
                </div>
              </div>
            </li>
            <li className="d-flex mb-4 pb-1">
              <div className="avatar flex-shrink-0 me-3">
                <span className="avatar-initial rounded bg-label-success"><i className="bx bx-closet"></i></span>
              </div>
              <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                <div className="me-2">
                  <h6 className="mb-0">Main Course</h6>
                  <small className="text-muted">xxxx sxxx xx</small>
                </div>
                <div className="user-progress">
                  <small className="fw-semibold">560</small>
                </div>
              </div>
            </li>
            <li className="d-flex mb-4 pb-1">
              <div className="avatar flex-shrink-0 me-3">
                <span className="avatar-initial rounded bg-label-info"><i className="bx bx-home-alt"></i></span>
              </div>
              <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                <div className="me-2">
                  <h6 className="mb-0">Desserts</h6>
                  <small className="text-muted">xxxx</small>
                </div>
                <div className="user-progress">
                  <small className="fw-semibold">90</small>
                </div>
              </div>
            </li>

            <li className="d-flex mb-4 pb-1">
              <div className="avatar flex-shrink-0 me-3">
                <span className="avatar-initial rounded bg-label-info"><i className="bx bx-home-alt"></i></span>
              </div>
              <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                <div className="me-2">
                  <h6 className="mb-0">Drinks</h6>
                  <small className="text-muted">xxxx</small>
                </div>
                <div className="user-progress">
                  <small className="fw-semibold">90</small>
                </div>
              </div>
            </li>

            <li className="d-flex mb-4 pb-1">
              <div className="avatar flex-shrink-0 me-3">
                <span className="avatar-initial rounded bg-label-info"><i className="bx bx-home-alt"></i></span>
              </div>
              <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                <div className="me-2">
                  <h6 className="mb-0">Service</h6>
                  <small className="text-muted">xxxx</small>
                </div>
                <div className="user-progress">
                  <small className="fw-semibold">90</small>
                </div>
              </div>
            </li>

            <li className="d-flex">
              <div className="avatar flex-shrink-0 me-3">
                <span className="avatar-initial rounded bg-label-secondary"
                  ><i className="bx bx-football"></i
                ></span>
              </div>
              <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                <div className="me-2">
                  <h6 className="mb-0">Ambience</h6>
                  <small className="text-muted">xxxxx</small>
                </div>
                <div className="user-progress">
                  <small className="fw-semibold">99</small>
                </div>
              </div>
            </li>
            
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
  )
}

export default ReviewByCategory