import React, { useRef, useEffect } from "react";
import * as echarts from "echarts";

const SentimentTrend = () => {
  const chartContainer = useRef(null);

  const getLast7Days = () => {
    const dates = [];
    for (let i = 6; i >= 0; i--) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      const formattedDate = `${date.getMonth() + 1}/${date.getDate()}`;
      dates.push(formattedDate);
    }
    return dates;
  };

  useEffect(() => {
    let myChart = null;

    if (chartContainer && chartContainer.current) {
      myChart = echarts.init(chartContainer.current);

      const option = {
        xAxis: {
          type: 'category',
          // data: ['11/1', '11/2', '11/3', '11/4', '11/5', '11/6', '11/7'],
          data: getLast7Days(),
        },
        yAxis: {
          type: 'value',
          min: 0,
          max: 100, // Adjust this value to make the y-axis bigger or smaller
        },
        series: [
          {
            name: 'Negative',
            data: [10, 20, 30, 40, 20, 10, 5],
            type: 'line',
            smooth: true,
            lineStyle: { color: 'rgb(255, 99, 132)' },
          },
          {
            name: 'Neutral',
            data: [65, 59, 80, 81, 56, 55, 40],
            type: 'line',
            smooth: true,
            lineStyle: { color: 'rgb(250,174,50)' },
          },
          {
            name: 'Positive',
            data: [35, 40, 60, 67, 40, 45, 30],
            type: 'line',
            smooth: true,
            lineStyle: { color: 'rgb(75, 192, 192)' },
          },
        ],
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: ['Negative', 'Neutral', 'Positive'],
        },
      };

      myChart.setOption(option);
    }

    // Cleanup function to be run when the component unmounts
    return () => {
      if (myChart) {
        myChart.dispose();
      }
    };
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 mb-4 order-0">
          <div ref={chartContainer} style={{ width: '100%', height: '400px' }} />
        </div>
      </div>
    </div>
  );
};

export default SentimentTrend;















// import React, { useRef, useEffect } from "react";
// import {
//   Chart,
//   LineController,
//   LineElement,
//   PointElement,
//   LinearScale,
//   Title,
//   CategoryScale,
// } from "chart.js";

// Chart.register(
//   LineController,
//   LineElement,
//   PointElement,
//   LinearScale,
//   Title,
//   CategoryScale
// );

// const SentimentTrend = () => {
//   const chartContainer = useRef(null);

//   useEffect(() => {
//     let myChart = null;
  
//     if (chartContainer && chartContainer.current) {
//       const ctx = chartContainer.current.getContext('2d');
      
//       const data = {
//         labels: ['5/1', '5/2', '5/3', '5/4', '5/5', '5/6', '5/7'],
//         datasets: [
//           {
//             label: 'Negative',
//             data: [10, 20, 30, 40, 20, 10, 5],
//             fill: false,
//             borderColor: 'rgb(255, 99, 132)',
//             tension: 0.1
//           },
//           {
//             label: 'Neutral',
//             data: [65, 59, 80, 81, 56, 55, 40],
//             fill: false,
//             borderColor: 'rgb(250,174,50)',
//             tension: 0.1
//           },
//           {
//             label: 'Positive',
//             data: [35, 40, 60, 67, 40, 45, 30],
//             fill: false,
//             borderColor: 'rgb(75, 192, 192)',
//             tension: 0.1
//           },
//         ]
//       };
  
//       const config = {
//         type: 'line',
//         data: data,
//         options: {
//           scales: {
//             y: {
//               min: 0,
//               max: 100, // Adjust this value to make the y-axis bigger or smaller
//             }
//           }
//         }
//       };
  
//       myChart = new Chart(ctx, config);
//     }
  
//     // Cleanup function to be run when the component unmounts
//     return () => {
//       if (myChart) {
//         myChart.destroy();
//       }
//     };
//   }, []);

//   return (
//     <div className="container">
//       <div className="row">
//         <div className="col-lg-12 mb-4 order-0">
//           <canvas ref={chartContainer} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SentimentTrend;
