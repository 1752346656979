
import React from 'react';
import DashboardP1 from './DashboardP1';
import ReviewByCategory from './ReviewByCategory';
import TopReviewByItem from './TopReviewByItem'; // Import the missing component
import TopReviews from './TopReviews'; // Import the missing component
import SentimentTrend from './SentimentTrend';
import St2 from './St2';
import SentimentProgress from './SentimentProgress';
import ProgressLine from './ProgressLine';
import TestComponent from '../TestComponent';
import 'bootstrap/dist/css/bootstrap.min.css';
import Test from './Test';
import Footer from './Footer';

const Dashboard = () => {
    return (
        <div className="content-wrapper d-flex flex-column align-items-end  ">
            <DashboardP1 />

            {/* <ProgressLine /> */}
            {/* <SentimentTrend /> */}
            {/* <SentimentProgress /> */}
            {/* <ReviewByCategory /> */}
            {/* <TopReviews /> */}
            <TopReviewByItem />
            {/* <SentimentProgress /> */}

            {/* <Test /> */}

            {/* <ProgressLine />
            <SentimentTrend /> */}
            <Footer />

            {/* <TestComponent /> */}
            {/* <St2 /> */}



        </div>
    )
}

export default Dashboard