import React from "react";
import SentimentTrend from "./SentimentTrend2";
import SentimentProgress from "./SentimentProgress";
import ProgressLine from "./ProgressLine";

const DashboardP1 = () => {
  const userName = "Johnny";
  const custHappiness = "72%";
  const userMsg2 = "Your Chicken Biryani is doing very good, but the Samosas are getting critical reviews. Check for all the details below.";
  const reviewCount = "88";
  const reviewCountPositive = "66";
  const reviewCountNegative = "22";
  //different in percentage for the review compared to average of last 30 days.
  const reviewCountDiff = "72.80";
  const reviewCountPositiveDiffPercent = "28.42";
  const reviewCountNegativeDiffPercent = "14.82";

  const reviewCountPositiveYear = "66";
  const reviewCountNeutralYear = "12";
  const reviewCountNegativeYear = "10";

  const reviewCountPrev1 = "66.8K";
  const reviewCountPrev2 = "44.5K";


  
  return (
    <div className="container flex-grow-1 container-p-y">
      <div className="row">
        <div className="col-md-12 mb-4 order-0 ">
          <div className="bg-white shadow-lg py-2 rounded-lg">
            <div className="d-flex align-items-end row">
              <div className="col-sm-7">
                <div className="card-body px-3">
                  <h5 className="card-title text-primary">Hello {userName}! 🎉</h5>
                  &nbsp;
                  <p className="mb-4">
                    Your customer happiness is
                    <span className="fw-bold"> {custHappiness}</span> more today. {userMsg2}
                  </p>
                </div>
              </div>
              <div className="col-sm-5 text-center text-sm-left">
                <div className="card-body pb-0 px-0 px-md-4 ">
                  <img
                    src="../assets/img/illustrations/man-with-laptop-light.png"
                    className="h-40 mx-auto"
                    alt="View Badge User"
                    data-app-dark-img="illustrations/man-with-laptop-dark.png"
                    data-app-light-img="illustrations/man-with-laptop-light.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-1 gap-4 mb-16">
          {/* first card */}
          <div className="bg-white shadow-lg py-3 rounded-lg px-3">
            <div className="card-body">
              <div className="card-title d-flex align-items-start justify-content-between">
                <div className="avatar flex-shrink-0">
                    <div className="me-2">
                      <span className="badge bg-label-primary p-2">
                        <i className="bx bxs-calendar-star text-primary fs-2"></i>
                      </span>
                    </div>
                  {/* <img
                    src="../assets/img/icons/unicons/chart-success.png"
                    alt="chart success"
                    className="rounded"
                  /> */}
                </div>
                <div className="dropdown">
                  <button
                    className="btn p-0"
                    type="button"
                    id="cardOpt3"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="bx bx-dots-vertical-rounded"></i>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="cardOpt3"
                  >
                    <a className="dropdown-item" href="javascript:void(0);">
                      View More
                    </a>
                    <a className="dropdown-item" href="javascript:void(0);">
                      Delete
                    </a>
                  </div>
                </div>
              </div>
              <span className="fw-semibold d-block mb-1">
                Count of Reviews
              </span>
              <h3 className="card-title mb-2">{reviewCount}</h3>
              <small className={`fw-semibold ${reviewCountDiff > 0 ? 'text-success' : 'text-danger'}`}>
                <i className={`bx ${reviewCountDiff > 0 ? 'bx-up-arrow-alt' : 'bx-down-arrow-alt'}`}></i> {reviewCountDiff}%
                &nbsp;&nbsp;
               <i className="bx bxs-info-circle text-info custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="Percentage difference compared to the average of last 30 days."></i>

              </small>
            </div>
          </div>

          <div className="bg-white shadow-lg py-3 rounded-lg px-3">
            <div className="card-body">
              <div className="card-title d-flex align-items-start justify-content-between">
                <div className="avatar flex-shrink-0">
                   <div className="me-2">
                      <span className="badge bg-label-primary p-2">
                        <i className="bx bxs-calendar-star text-success fs-2"></i>
                      </span>
                    </div>
                  {/* <img
                    src="../assets/img/icons/unicons/wallet-info.png"
                    alt="Credit Card"
                    className="rounded"
                  /> */}
                </div>
                <div className="dropdown">
                  <button
                    className="btn p-0"
                    type="button"
                    id="cardOpt6"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="bx bx-dots-vertical-rounded"></i>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="cardOpt6"
                  >
                    <a className="dropdown-item" href="javascript:void(0);">
                      View More
                    </a>
                    <a className="dropdown-item" href="javascript:void(0);">
                      Delete
                    </a>
                  </div>
                </div>
              </div>
              <span>Positive Reviews</span>
              <h3 className="card-title text-nowrap mb-1">{reviewCountPositive}</h3>
              <small className={`fw-semibold ${reviewCountPositiveDiffPercent > 0 ? 'text-success' : 'text-danger'}`}>
                <i className={`bx ${reviewCountPositiveDiffPercent > 0 ? 'bx-up-arrow-alt' : 'bx-down-arrow-alt'}`}></i> {reviewCountPositiveDiffPercent}%
                &nbsp;&nbsp;
               <i className="bx bxs-info-circle text-info" data-bs-toggle="tooltip" data-bs-placement="top" title="Percentage difference compared to the average of last 30 days."></i>

              </small>
            </div>
          </div>
          <div className="bg-white shadow-lg py-3 rounded-lg px-3">
            <div className="card-body">
              <div className="card-title d-flex align-items-start justify-content-between">
                <div className="avatar flex-shrink-0">
                   <div className="me-2">
                      <span className="badge bg-label-primary p-2">
                        <i className="bx bxs-calendar-star text-danger fs-2"></i>
                      </span>
                    </div>
                  {/* <img
                    src="../assets/img/icons/unicons/paypal.png"
                    alt="Credit Card"
                    className="rounded"
                  /> */}
                </div>
                <div className="dropdown">
                  <button
                    className="btn p-0"
                    type="button"
                    id="cardOpt4"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="bx bx-dots-vertical-rounded"></i>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="cardOpt4"
                  >
                    <a className="dropdown-item" href="javascript:void(0);">
                      View More
                    </a>
                    <a className="dropdown-item" href="javascript:void(0);">
                      Delete
                    </a>
                  </div>
                </div>
              </div>
              <span className="d-block mb-1">Negative Reviews</span>
              <h3 className="card-title text-nowrap mb-2">{reviewCountNegative}</h3>
              <small className={`fw-semibold ${reviewCountNegativeDiffPercent > 0 ? 'text-success' : 'text-danger'}`}>
                <i className={`bx ${reviewCountNegativeDiffPercent > 0 ? 'bx-up-arrow-alt' : 'bx-down-arrow-alt'}`}></i> {reviewCountNegativeDiffPercent}%
                &nbsp;&nbsp;
               <i className="bx bxs-info-circle text-info" data-bs-toggle="tooltip" data-bs-placement="top" title="Percentage difference compared to the average of last 30 days."></i>

              </small>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-12 col-md-4 order-1">
        </div> */}



        {/* <!-- Total Sentiments  --> */}

        <div className="col-12 col-md-12 order-2 order-md-3 order-lg-2 mb-4">
          <div className="bg-white shadow-lg rounded-lg">
            <div className="row row-bordered g-0">
              <div className="col-md-8">
                <h5 className="bg-[#9B5BA0] !text-white py-2 rounded-tl-lg rounded-tr-lg">
                  Reviews over last one week{" "}
                </h5>
                {/* <div id="totalRevenueChart" className="px-2"></div> */}
                <div className="mt-5"> <SentimentTrend />   </div>
                {/* Adding widget here */}
              </div>

              {/* <!-- Growth for complete year Report --> */}
              <div className="col-md-4">
                <div className="card-body">
                  <div className="text-center">
                    <div className="dropdown mt-3">
                      <button
                        className="!bg-[#9B5BA0] !text-white !border !border-[#D2D2D2] btn btn-sm btn-outline-primary dropdown-toggle"
                        type="button"
                        id="growthReportId"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        2024
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-end"
                        aria-labelledby="growthReportId"
                      >
                        <a className="dropdown-item" href="javascript:void(0);">
                          2023
                        </a>
                        <a className="dropdown-item" href="javascript:void(0);">
                          2022
                        </a>
                        <a className="dropdown-item" href="javascript:void(0);">
                          2021
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div id="growthChart"></div> */}
                <div>
                  <SentimentProgress positive={reviewCountPositiveYear} neutral={reviewCountNeutralYear} negative={reviewCountNegativeYear} />
                </div>
                <div className="text-center fw-semibold pt-3 mb-2">
                Yearly Sentiment Improvement
                  <small className={`fw-semibold ${reviewCountDiff > 0 ? 'text-success' : 'text-danger'}`}>
                    <i className={`bx ${reviewCountDiff > 0 ? 'bx-up-arrow-alt' : 'bx-down-arrow-alt'}`}></i>{reviewCountDiff}%
                  </small>
                </div>

                <div className="d-flex px-xxl-4 px-lg-2 p-4 gap-xxl-3 gap-lg-1 gap-3 justify-content-between">
                  <div className="d-flex">
                    <div className="me-2">
                      <span className="badge bg-label-primary p-2">
                        <i className="bx bx-chat text-primary"></i>
                      </span>
                    </div>
                    <div className="d-flex flex-column">
                      <small>2023</small>
                      <h6 className="mb-0">{reviewCountPrev1}</h6>
                      <i className="bx bxs-info-circle text-info" data-bs-toggle="tooltip" data-bs-placement="top" title="Total count of all reviews"></i>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="me-2">
                      <span className="badge bg-label-info p-2">
                        <i className="bx bx-chat text-info"></i>
                      </span>
                    </div>
                    <div className="d-flex flex-column">
                      <small>2022</small>
                      <h6 className="mb-0">{reviewCountPrev2}</h6>
                      <i className="bx bxs-info-circle text-info" data-bs-toggle="tooltip" data-bs-placement="top" title="Total count of all reviews"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* <!--/ Total Revenue --> */}
        <div className="col-12 col-md-2  order-3 order-md-2">
          <div className="row">
            <div className="col-12 mb-4">

            </div>

            {/* Hapiness Score to show how happy is the customer as compared to last few days */}
            <div className="col-6 mb-4 d-none">
              <div className="card">
                <div className="card-body">
                  <div className="card-title d-flex align-items-start justify-content-between">
                    <div className="avatar flex-shrink-0">
                      <img
                        src="../assets/img/icons/unicons/cc-primary.png"
                        alt="Credit Card"
                        className="rounded"
                      />
                    </div>
                    <div className="dropdown">
                      <button
                        className="btn p-0"
                        type="button"
                        id="cardOpt1"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="bx bx-dots-vertical-rounded"></i>
                      </button>
                      <div className="dropdown-menu" aria-labelledby="cardOpt1">
                        <a className="dropdown-item" href="javascript:void(0);">
                          View More
                        </a>
                        <a className="dropdown-item" href="javascript:void(0);">
                          Delete
                        </a>
                      </div>
                    </div>
                  </div>
                  <span className="fw-semibold d-block mb-1">
                    Happiness Score
                  </span>
                  <h3 className="card-title mb-2">83</h3>
                  <small className="text-success fw-semibold">
                    <i className="bx bx-up-arrow-alt"></i> +28.14%
                  </small>
                </div>
              </div>
            </div>

            {/* Customer Happiness Report is a trend line for creating happiness sentiment line 
            based on total positive reviews trending for last 30 days.... */}

            <div className="col-12 mb-4 d-none">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between flex-sm-row flex-column gap-3">
                    <div className="d-flex flex-sm-column flex-row align-items-start justify-content-between">
                      <div className="card-title">
                        <h5 className="text-nowrap mb-2">
                          Customer Happiess Report
                        </h5>
                        <span className="badge bg-label-warning rounded-pill">
                          Last 30
                        </span>
                      </div>
                      <div className="card-body">
                        <div className="row mb-0">
                          <div className="col-10">
                            <ProgressLine />
                          </div>

                          <div className="col-2">
                            <div className="mt-sm-auto">
                              <small className="text-success text-nowrap fw-semibold">
                                <i className="bx bx-chevron-up"></i> 68.2%
                              </small>
                              <h3 className="text-nowrap">84</h3>
                            </div>
                          </div>
                        </div>

                        {/* <div id="profileReportChart"></div> */}



                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardP1;
