import React from 'react';
import reviewsData from "../allreview.json";

const AllReviews = () => {
  const getClassByHappinessScore = (happinessScore) => {
    if (happinessScore >= 0 && happinessScore <= 3) {
      return "bg-danger bg-opacity-25";
    } else if (happinessScore >= 4 && happinessScore <= 5) {
      return "bg-warning bg-opacity-25";
    } else if (happinessScore === 6) {
      return "bg-info bg-opacity-25";
    } else {
      return "bg-success bg-opacity-25";
    }
  };

  return (
    <div className="container flex-grow-1 container-p-y">
      <h4 className="fw-bold py-3 mb-4">All Reviews</h4>

      <div className="bg-white shadow-lg rounded-lg mb-4">
        <div className="row g-0">
          <div className=" p-4">
            <div className="small fw-semibold mb-3"></div>
            <div className="row">
              {reviewsData.reviews.map((review, index) => (
                <div className="col-md-6 mb-3">
                  <div
                    key={index}
                    className={`card border-orange ${getClassByHappinessScore(
                      review.happinessScore
                    )}`}
                  >
                    <div className="card-header d-flex align-items-center">
                      <i className="bx bx-user-voice me-2"></i>
                      <div className="me-auto fw-bold text-bold">{review.reviewerName}</div>
                      <small>{review.reviewedTimeAgo}</small>
                    </div>
                    <div className="card-body">{review.text}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllReviews;
