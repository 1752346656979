import React from 'react';
const data = require('./allReviewsForItem.json');

function TestComponent() {
    // Load the JSON data from the file

    // Extract the required information for each selected_options value
    const result = [];
    for (const item of data) {
        for (const selected_option of item.selected_options) {
            const { selected_value, question_id, count } = selected_option;
            result.push({ selected_value, question_id, count });
        }
    }

    // Display the extracted information in the React application
    return (
        <div>
            {result.map((item, index) => (
                <p key={index}>{JSON.stringify(item)}</p>
            ))}
        </div>
    );
}

export default TestComponent;
