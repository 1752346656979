import React, { useState, useEffect } from "react";
//import reviewData2 from "../reviewByItem.json";
//import topReview from "../topReviewsForItems.json";
import SentimentProgress from "./SentimentProgress";
import "bootstrap/dist/css/bootstrap.min.css";
//#import { extractData } from "./helper";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css'; // 
import { IoIosArrowDown } from "react-icons/io";
import { TiArrowSortedDown } from "react-icons/ti";

const TopReviewByItem = () => {
  const [reviewData, setReviewData] = useState([]);
  const [expandedItems, setExpandedItems] = useState([]);
  const [topReview, setTopReview] = useState([]);
  console.log("🚀 ~ TopReviewByItem ~ topReview:", topReview)

  const item_id = "1772OUmhCDU2jgYXN374";
  const restaurant_id = "nTSlYFl1WMPjw3Vv2rvg";

  const fechReviewForItem = async (item_id, restaurant_id) => {
    try {
      let payload = {
        restaurant_id: restaurant_id,
        item_id: item_id,
        today: false,
        yesterday: false,
        week: false,
        month: false,
        year: true,
        level: "detail",
        review_sentiment: "",
      };

      const response = await fetch(
        "https://restaurantfeedback-453.ue.r.appspot.com/analytics/get_feedback_by_restaurant_id_api",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();
      console.log("data", data);
      return data;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Function to extract all detailed reviews and frequency from the response
  const extractData = (data) => {
    const result = [];

    data.data.forEach((item) => {
      item.survey_items.feedback.forEach((feedback2) => {
        // Check if feedback2.question_id is defined before calling endsWith
        if (
          feedback2.question_id &&
          (feedback2.question_id.endsWith("g") ||
            feedback2.question_id.endsWith("b"))
        ) {
          const type = feedback2.question_id.endsWith("g") ? "good" : "bad";

          feedback2.selected_option.forEach((option) => {
            let existingOption = result.find((o) => o.text === option);

            if (!existingOption) {
              existingOption = { text: option, count: 0, type: type };
              result.push(existingOption);
            }

            existingOption.count += 1;
          });
        }
      });
    });

    // Sort the results based on count in descending order
    result.sort((a, b) => b.count - a.count);
    //console.log("result", result);

    return result;
  };

  const loadDetails = (index, item_id) => {
    toggleCollapse(index);

    console.log("item", item_id);
    fechReviewForItem(item_id, restaurant_id).then((data) => {
      console.log("data", data);
      
      //format the data
      const comments = extractData(data);
      console.log("extracted data", data);
      console.log("top review item_id", item_id);
      //setTopReview((prevTopReview) => ({ ...prevTopReview, [item_id]: data }));
      setTopReview((prevTopReview) => ({  ...prevTopReview, [item_id]: { ...prevTopReview[item_id], selectedComment: comments } }));
      console.log("top review", { ...topReview, [item_id]: comments });

      //now fetch the count per category
      const categories = countFeedbackByCategory(data);
      console.log("categories", categories);
      setTopReview((prevTopReview) => ({  ...prevTopReview, [item_id]: { ...prevTopReview[item_id], category: categories } }));
      
    });
  };


  //Function to count the total for different categories for an item. 
  const countFeedbackByCategory = (data) => {
    const categories = {
      cat1: { good: 0, bad: 0, neutral: 0 },
      cat2: { good: 0, bad: 0, neutral: 0 },
      cat3: { good: 0, bad: 0, neutral: 0 },
      cat4: { good: 0, bad: 0, neutral: 0 },
    };
  
    data.data.forEach((item) => {
      item.survey_items.feedback.forEach((feedback2) => {
        const { question_id, user_rating } = feedback2;
  
        if (question_id && user_rating !== undefined) {
          let category;
          if (question_id === "Q1") category = categories.cat1;
          else if (question_id === "Q2") category = categories.cat2;
          else if (question_id === "Q3") category = categories.cat3;
          else if (question_id === "Q4") category = categories.cat4;
  
          if (category) {
            if (user_rating > 3) {
              category.good += 1;
            } else if (user_rating < 3) {
              category.bad += 1;
            } else {
              category.neutral += 1;
            }
          }
        }
      });
    });
  
    return categories;
  };

  const toggleCollapse = (index) => {
    if (expandedItems.includes(index)) {
      setExpandedItems(expandedItems.filter((item) => item !== index));
    } else {
      setExpandedItems([...expandedItems, index]);
    }
  };

  // for dropdown for sorting the items
  const [selectedText, setSelectedText] = useState("Total Reviews");
  const [isOpen, setIsOpen] = useState(false); // Add this line
  const handleSelect = (text) => {
    setSelectedText(text);
    setIsOpen(false); // Add this line
    sortItems(text, selectedDate);
  };

  //For the date drop down
  const [selectedDate, setSelectedDate] = useState("Today");
  const [isOpenDate, setIsOpenDate] = useState(false);
  const handleSelectDate = (text) => {
    setSelectedDate(text);
    setIsOpenDate(false);
    sortItems(selectedText, text);
  };

  //Sort function for the most reviewed items based on selected dowpdown
  const sortItems = (selectedText, selectedDate) => {
    let sorted_reviews = null;
    switch (selectedDate) {
      case "Today":
        // code for "Today" case
        break;
      case "Yesterday":
        // code for "Yesterday" case
        break;
      case "Last 7 Days":
        // code for "Last 7 Days" case
        break;
      case "Last 30 Days":
        // code for "Last 30 Days" case
        break;
      default:
        // code for default case
        break;
    }

    switch (selectedText) {
      case "Negative Reviews":
        // code for "Total Negative Reviews" case
        //sorted_reviews = sorted(data["reviews"], key=lambda x: x["countGood"], reverse=True)
        sorted_reviews = [...reviewData.reviews].sort(
          (a, b) => b.countBad - a.countBad
        );
        console.log("sorted reviews", sorted_reviews);
        setReviewData({ ...reviewData, reviews: sorted_reviews });
        break;
      case "Positive Reviews":
        // code for "Total Positive Reviews" case
        sorted_reviews = [...reviewData.reviews].sort(
          (a, b) => b.countGood - a.countGood
        );
        setReviewData({ ...reviewData, reviews: sorted_reviews });
        break;
      case "Neutral Reviews":
        // code for "Total Neutral Reviews" case
        sorted_reviews = [...reviewData.reviews].sort(
          (a, b) => b.countNeutral - a.countNeutral
        );
        setReviewData({ ...reviewData, reviews: sorted_reviews });
        break;
      case "All Reviews":
        // code for "Total Reviews" case
        sorted_reviews = [...reviewData.reviews].sort(
          (a, b) =>
            b.countGood +
            b.countNeutral +
            b.countBad -
            (a.countGood + a.countNeutral + a.countBad)
        );
        setReviewData({ ...reviewData, reviews: sorted_reviews });
        break;
      default:
        // code for default case
        break;
    }
  };

  const colorBadge = "bg-dark text-white me-1";

  useEffect(() => {
    const fetchData = async (reviewSentiment) => {
      try {
        const payload = {
          restaurant_id: "nTSlYFl1WMPjw3Vv2rvg",
          k: 50,
          item_id: "",
          sentiment: reviewSentiment, // positive, negative, neutral
          today: false,
          yesterday: false,
          week: false,
          month: false,
          year: true,
        };

        const response = await fetch(
          "https://restaurantfeedback-453.ue.r.appspot.com/analytics/get_top_k_items_by_sentiment_restaurant_id_api",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const reviewSentiments = ["positive", "negative", "neutral"];
    //Call the backend for each sentiment
    Promise.all(reviewSentiments.map((sentiment) => fetchData(sentiment))).then(
      (responses) => {
        //const flattenedData = responses.flat();
        console.log("responses", responses);
        const combinedData = combineResponses(...responses);
        setReviewData({ reviews: combinedData });
        console.log("combined  data", combinedData);
        console.log("review data", reviewData);
      }
    );

    console.log("combined data", reviewData);
  }, []); // Empty dependency array means this runs once on mount

  //Adding a functin to combine data from backedn responses.
  //Remove this later
  const combineResponses = (goodReviews, badReviews, neutralReviews) => {
    const combined = {};

    // Combine good reviews
    console.log("good reviews", goodReviews.data);
    goodReviews.data.forEach(({ item_name: item, item_id, count }) => {
      if (!combined[item]) {
        combined[item] = {
          item,
          item_id,
          countGood: 0,
          countBad: 0,
          countNeutral: 0,
        };
      }
      combined[item].countGood = count;
    });

    // Combine bad reviews
    badReviews.data.forEach(({ item_name: item, item_id, count }) => {
      if (!combined[item]) {
        combined[item] = {
          item,
          item_id,
          countGood: 0,
          countBad: 0,
          countNeutral: 0,
        };
      }
      combined[item].countBad = count;
    });

    // Combine neutral reviews
    neutralReviews.data.forEach(({ item_name: item, item_id, count }) => {
      if (!combined[item]) {
        combined[item] = {
          item,
          item_id,
          countGood: 0,
          countBad: 0,
          countNeutral: 0,
        };
      }
      combined[item].countNeutral = count;
    });

    return Object.values(combined);
  };

  return (
    <div className="container ">
      <div className="row align-items-center">
        <div className=" w-full mb-20">
          <div className="card h-100 shadow-lg">
            <div className="card-header !bg-[#9B5BA0] text-white">
              <div className="row">
                <div className="col-12 d-flex flex-wrap ">
                  <div className="col-12 col-sm-6">
                    <h5 className="card-title m-0 me-2 fs-4 text-start">
                      Top Reviewed Items
                    </h5>
                  </div>

                  {/* Start Date Range */}

                  <div
                    className="col-6 col-sm-2 mt-2 dropdown align-items-center"
                    onClick={() => setIsOpenDate(!isOpenDate)}
                  >
                    {" "}
                    {/* Add this line */}
                    <label htmlFor="dropdownMenuDate" className="me-2">
                      Date:
                    </label>
                    <div className="btn-group ">
                      <button
                        id="dropdownMenuDate"
                        type="button"
                        className="btn btn-outline-info dropdown-toggle !bg-[#ffff] !text-[#9B5BA0] !border !border-[#D2D2D2]"
                        data-bs-toggle="dropdown"
                        aria-expanded={isOpenDate} // Modify this line
                      >
                        {selectedDate}
                      </button>
                      <ul
                        className={`dropdown-menu ${isOpenDate ? "show" : ""}`}
                      >
                        {" "}
                        {/* Modify this line */}
                        <li>
                          <a
                            className="dropdown-item"
                            href="javascript:void(0);"
                            onClick={() => handleSelectDate("Today")}
                          >
                            Today
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="javascript:void(0);"
                            onClick={() => handleSelectDate("Yesterday")}
                          >
                            Yesterday
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="javascript:void(0);"
                            onClick={() => handleSelectDate("Last 7 Days")}
                          >
                            Last 7 Days
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="javascript:void(0);"
                            onClick={() => handleSelectDate("Last 30 Days")}
                          >
                            Last 30 Days
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Date Range */}

                  {/* for Sorting dropdown */}
                  <div
                    className="col-6 col-sm-3 mt-2 dropdown align-items-center justify-content-center"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {" "}
                    {/* Add this line */}
                    <label for="dropdownMenu" className="me-2">
                      Sort By:
                    </label>
                    <div className="btn-group">
                      <button
                        id="dropdownMenu"
                        type="button"
                        className="btn btn-outline-info dropdown-toggle !bg-[#ffff] !text-[#9B5BA0] !border !border-[#D2D2D2]"
                        data-bs-toggle="dropdown"
                        aria-expanded={isOpen} // Modify this line
                      >
                        {selectedText}
                      </button>
                      <ul className={`dropdown-menu ${isOpen ? "show" : ""}`}>
                        {" "}
                        {/* Modify this line */}
                        <li>
                          <a
                            className="dropdown-item"
                            href="javascript:void(0);"
                            onClick={() => handleSelect("Negative Reviews")}
                          >
                            Negative Reviews
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="javascript:void(0);"
                            onClick={() => handleSelect("Positive Reviews")}
                          >
                            Positive Reviews
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="javascript:void(0);"
                            onClick={() => handleSelect("Neutral Reviews")}
                          >
                            Neutral Reviews
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="javascript:void(0);"
                            onClick={() => handleSelect("All Reviews")}
                          >
                            All Reviews
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Start adding accordian here */}
            <div>
              <Accordion allowMultipleExpanded allowZeroExpanded>
                {reviewData.reviews && reviewData.reviews.map((item, index) => {
                  const collapseId = `collapseExample${index}`;
                  const totalCount =
                    item.countGood + item.countBad + item.countNeutral;
                  const percentGood = (item.countGood / totalCount) * 100;
                  const percentNeutral =
                    (item.countNeutral / totalCount) * 100;
                  const percentBad = (item.countBad / totalCount) * 100;

                  return (
                    <AccordionItem key={index}>
                      <AccordionItemHeading>
                        <AccordionItemButton as="div" className="custom-accordion-button m-2 ">
                          <h2 className="accordion-header " id="headingOne">
                            <button
                              type="button"
                              className="bg-[#e2a6d83b] w-full flex items-center p-3 rounded-lg"
                              data-bs-toggle="collapse"
                              data-bs-target={`#${collapseId}`}
                              aria-expanded={expandedItems.includes(index)}
                              aria-controls={collapseId}
                              onClick={() => loadDetails(index, item.item_id)}
                            >
                              <div className="avatar flex-shrink-0 me-3">
                                <img
                                  src="../assets/img/icons/food/food.png"
                                  alt="User"
                                  className="bx-dots-vertical-rounded"
                                />
                              </div>
                              <div className="d-flex w-100 flex-wrap align-items-center justify-content-between">
                                <h6 className="!w-fit">{item.item}</h6>
                                <div
                                  className="col-8 col-sm-8 progress me-2"
                                  role="progressbar"
                                  aria-label="Success example"
                                  aria-valuenow="25"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  <div
                                    className="progress-bar bg-success"
                                    style={{ width: `${percentGood}%` }}
                                  >
                                    {item.countGood}
                                  </div>
                                  <div
                                    className="progress-bar bg-warning"
                                    style={{ width: `${percentNeutral}%` }}
                                  >
                                    {item.countNeutral}
                                  </div>
                                  <div
                                    className="progress-bar bg-danger"
                                    style={{ width: `${percentBad}%` }}
                                  >
                                    {item.countBad}
                                  </div>
                                </div>
                              </div>
                              <TiArrowSortedDown size={21} />
                            </button>
                          </h2>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="accordion-body p-3 bg-light shadow rounded">
                          <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-4 mb-4 gap-3">
                            <div className="rounded-lg mx-2 shadow-lg border border-[#D2D2D2] ">
                            {topReview[item.item_id] && topReview[item.item_id].category && (
                              <SentimentProgress
                                positive={topReview[item.item_id].category.cat1.good}
                                height={'350px'}
                                neutral={topReview[item.item_id].category.cat1.neutral}
                                negative={topReview[item.item_id].category.cat1.bad}
                              />
                            )}
                              <h5 className="bg-[#9B5BA0] m-0 py-2 rounded-bl-lg rounded-br-lg text-white">
                                Flavor
                              </h5>
                            </div>

                            <div className="rounded-lg mx-2 shadow-lg border border-[#D2D2D2]">
                            {topReview[item.item_id] && topReview[item.item_id].category && (
                              <SentimentProgress
                                positive={topReview[item.item_id].category.cat2.good}
                                height={'350px'}
                                neutral={topReview[item.item_id].category.cat2.neutral}
                                negative={topReview[item.item_id].category.cat2.bad}
                              />
                            )}
                              <h5 className="bg-[#9B5BA0] m-0 py-2 rounded-bl-lg rounded-br-lg text-white">
                                Texture
                              </h5>
                            </div>
                            
                            <div className="rounded-lg mx-2 shadow-lg border border-[#D2D2D2]">
                            {topReview[item.item_id] && topReview[item.item_id].category && (
                              <SentimentProgress
                                positive={topReview[item.item_id].category.cat3.good}
                                height={'350px'}
                                neutral={topReview[item.item_id].category.cat3.neutral}
                                negative={topReview[item.item_id].category.cat3.bad}
                              />
                            )}
                              <h5 className="bg-[#9B5BA0] m-0 py-2 rounded-bl-lg rounded-br-lg text-white">
                                Freshness
                              </h5>
                            </div>
                            <div className="rounded-lg mx-2 shadow-lg border border-[#D2D2D2]">
                            {topReview[item.item_id] && topReview[item.item_id].category && (
                              <SentimentProgress
                                positive={topReview[item.item_id].category.cat4.good}
                                height={'350px'}
                                neutral={topReview[item.item_id].category.cat4.neutral}
                                negative={topReview[item.item_id].category.cat4.bad}
                              />
                            )}
                              <h5 className="bg-[#9B5BA0] m-0 py-2 rounded-bl-lg rounded-br-lg text-white">
                                Presentation
                              </h5>
                            </div>
                          </div>

                          <div className="row mb-4">
                            <div className="col-md mb-4 mb-md-0">
                              <div className="card shadow">
                                <h5 className="card-header">
                                  Top Positive Reviews
                                </h5>
                                <div className="card-body">
                                  {topReview &&
                                    topReview[item.item_id] &&
                                    topReview[item.item_id].selectedComment.map(
                                      (item2, index2) => {
                                        if (item2.type === "good") {
                                          //console.log("🚀 ~ TopReviewByItem ~ item2:", item2)
                                          return (
                                            <div
                                              key={index + index2}
                                              className={`alert align-left ${item2.type === "bad"
                                                ? "alert-danger"
                                                : item2.type === "neutral"
                                                  ? "alert-warning"
                                                  : "alert-success"
                                                } mt-2 mb-1`}
                                              role="alert"
                                            >
                                              <span
                                                className={`badge ${colorBadge}`}
                                              >
                                                {item2.count}
                                              </span>
                                              {item2.text}
                                            </div>
                                          );
                                        }

                                        return null;
                                      }
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md mb-4 mb-md-0">
                              <div className="card mb-2 shadow">
                                <h5 className="card-header">
                                  Top Negative Reviews
                                </h5>
                                <div className="card-body">
                                  {topReview &&
                                    topReview[item.item_id] &&
                                    topReview[item.item_id].selectedComment.map(
                                      (item2, index2) => {
                                        if (item2.type === "bad") {
                                          return (
                                            <div
                                              key={index + index2}
                                              className={`alert align-left ${item2.type === "bad"
                                                ? "alert-danger"
                                                : item2.type === "neutral"
                                                  ? "alert-warning"
                                                  : "alert-success"
                                                } mt-2 mb-1`}
                                              role="alert"
                                            >
                                              <span
                                                className={`badge ${colorBadge}`}
                                              >
                                                {item2.count}
                                              </span>
                                              {item2.text}
                                            </div>
                                          );
                                        }

                                        return null;
                                      }
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </div>


            {/* <div className="accordion accordion-flush  m-1">
              <div className="accordion-item  shadow mb-5" style={{ backgroundColor: "#F3E5F5", color: "#000" }}>
                {reviewData.reviews &&
                  reviewData.reviews.map((item, index) => {
                    const collapseId = `collapseExample${index}`;
                    const totalCount =
                      item.countGood + item.countBad + item.countNeutral;
                    const percentGood = (item.countGood / totalCount) * 100;
                    const percentNeutral =
                      (item.countNeutral / totalCount) * 100;
                    const percentBad = (item.countBad / totalCount) * 100;

                    return (
                      <React.Fragment key={index}>
                        <h2 className="accordion-header " id="headingOne">

                          <button
                            type="button"
                            className="accordion-button border-2 btn-lg btn btn-outline-secondary"
                            data-bs-toggle="collapse"
                            data-bs-target={`#${collapseId}`}
                            aria-expanded={expandedItems.includes(index)}
                            aria-controls={collapseId}
                            onClick={() => loadDetails(index, item.item_id)}
                          >
                            <div className="avatar flex-shrink-0 me-3">
                              <img
                                src="../assets/img/icons/food/food.png"
                                alt="User"
                                className="bx-dots-vertical-rounded"
                              />
                            </div>
                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between">
                              <h6 className="mb-1 col-3">{item.item}</h6>
                              <div
                                className="col-8 col-sm-8 progress me-2"
                                role="progressbar"
                                aria-label="Success example"
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                <div
                                  className="progress-bar bg-success"
                                  style={{ width: `${percentGood}%` }}
                                >
                                  {item.countGood}
                                </div>
                                <div
                                  className="progress-bar bg-warning"
                                  style={{ width: `${percentNeutral}%` }}
                                >
                                  {item.countNeutral}
                                </div>
                                <div
                                  className="progress-bar bg-danger"
                                  style={{ width: `${percentBad}%` }}
                                >
                                  {item.countBad}
                                </div>
                              </div>
                            </div>
                          </button>
                        </h2>

                        <div
                          id={collapseId}
                          className={` ${expandedItems.includes(index) ? "show" : ""
                            }`}
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body  shadow m-0">
                            <div className="row mb-3 mt-4">
                              <div className="col-md-3 mb-4 mb-md-0 ">
                                <div className="card shadow mb-3">
                                  <div className="card-body">
                                    <SentimentProgress
                                      positive={68}
                                      neutral={22}
                                      negative={10}
                                    />
                                  </div>
                                  <h5 className="card-footer mb-0 bg-info text-white">
                                    Flavor
                                  </h5>
                                </div>
                              </div>
                              <div className="col-md-3 mb-4 mb-md-0">
                                <div className="card shadow">
                                  <div className="card-body">
                                    <SentimentProgress
                                      positive={58}
                                      neutral={22}
                                      negative={20}
                                    />
                                  </div>
                                  <h5 className="card-footer mb-0 bg-info text-white">
                                    Texture
                                  </h5>
                                </div>
                              </div>
                              <div className="col-md-3 mb-4 mb-md-0">
                                <div className="card shadow">
                                  <div className="card-body">
                                    <SentimentProgress
                                      positive={50}
                                      neutral={20}
                                      negative={20}
                                    />
                                  </div>
                                  <h5 className="card-footer mb-0 bg-info text-white">
                                    Freshness
                                  </h5>
                                </div>
                              </div>
                              <div className="col-md-3 mb-4 mb-md-0">
                                <div className="card shadow">
                                  <div className="card-body">
                                    <SentimentProgress
                                      positive={60}
                                      neutral={10}
                                      negative={30}
                                    />
                                  </div>
                                  <h5 className="card-footer mb-0 bg-info text-white">
                                    Presentation
                                  </h5>
                                </div>
                              </div>
                            </div>

                            <div className="row mb-4">
                              <div className="col-md mb-4 mb-md-0">
                                <div className="card shadow">
                                  <h5 className="card-header">
                                    Top Positive Reviews
                                  </h5>
                                  <div className="card-body">
                                    {topReview &&
                                      topReview[item.item_id] &&
                                      topReview[item.item_id].map(
                                        (item2, index2) => {
                                          if (item2.type === "good") {
                                            console.log("🚀 ~ TopReviewByItem ~ item2:", item2.text)
                                            return (
                                              <div
                                                key={index + index2}
                                                className={`alert align-left ${item2.type === "bad"
                                                  ? "alert-danger"
                                                  : item2.type === "neutral"
                                                    ? "alert-warning"
                                                    : "alert-success"
                                                  } mt-2 mb-1`}
                                                role="alert"
                                              >
                                                <span
                                                  className={`badge ${colorBadge}`}
                                                >
                                                  {item2.count}
                                                </span>
                                                {item2.text}
                                              </div>
                                            );
                                          }

                                          return null;
                                        }
                                      )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md mb-4 mb-md-0">
                                <div className="card mb-2 shadow">
                                  <h5 className="card-header">
                                    Top Negative Reviews
                                  </h5>
                                  <div className="card-body">
                                    {topReview &&
                                      topReview[item.item_id] &&
                                      topReview[item.item_id].map(
                                        (item2, index2) => {
                                          if (item2.type === "bad") {
                                            return (
                                              <div
                                                key={index + index2}
                                                className={`alert align-left ${item2.type === "bad"
                                                  ? "alert-danger"
                                                  : item2.type === "neutral"
                                                    ? "alert-warning"
                                                    : "alert-success"
                                                  } mt-2 mb-1`}
                                                role="alert"
                                              >
                                                <span
                                                  className={`badge ${colorBadge}`}
                                                >
                                                  {item2.count}
                                                </span>
                                                {item2.text}
                                              </div>
                                            );
                                          }

                                          return null;
                                        }
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            </div> */}

            {/* end adding accordian here */}
          </div >
        </div >
      </div >
    </div >
  );
};

export default TopReviewByItem;
