import React, { useState, useEffect } from 'react';


const Menu = () => {
  const [menuData, setMenuData] = useState(null);

  useEffect(() => {
    //function to fetch data from api or json file
    const fetchMenuData = async () => {
      try {
        const response = await fetch('http://localhost:3000/menudata-delete.json');
        //Do we really need await here ?

        const data = await response.json();
        setMenuData(data.menu);


      } catch (error) {
        console.log("Error fetching menu data from server:", error);
      }

    };
    //call fuction to fetch data
    fetchMenuData();
  }, []);

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <h4 className="fw-bold py-3 mb-4">Menu Items</h4>


      {menuData && (
        <>
          {Object.entries(menuData).map(([category, items]) => (
            <div class="card shadow-lg mb-4">
              <h5 class="card-header">{category}</h5>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-6">
                    <small class="text-light fw-semibold"></small>
                    <div class="demo-inline-spacing mt-3">
                      <ul class="list-group">
                        {items.map((item) => (
                          <li class="list-group-item d-flex justify-content-between align-items-center">
                            {item.name}
                            <span class="badge bg-success">V</span>
                            <span class="badge bg-primary">{item.price}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export default Menu