import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Dashboard from "./components/Dashboard";
import Menu from "./components/Menu";
import AllReviews from "./components/AllReviews";
import 'bootstrap/dist/css/bootstrap.min.css';
// import logo from './rs2_big.png';
import logo from '../src/assets/img/logo/fulllogo_transparent_nobuffer.png';
import backgroundImage from '../src/assets/img/backgrounds/Background.svg';

import { GoogleLoginButton } from "react-social-login-buttons";
// Attempt a direct import from the dist folder or declare the module if necessary
// import { LoginSocialGoogle } from "reactjs-social-login/dist/index.d.ts"; // Example workaround
import { LoginSocialGoogle } from "reactjs-social-login"; // Assuming the library issue is resolved
import Header from "./components/Header";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const loggedIn = localStorage.getItem("isLoggedIn") === "true";
    setIsLoggedIn(loggedIn);
  }, []);

  const handleLoginSuccess = ({ provider, data }) => {
    console.log(provider, data);
    localStorage.setItem("isLoggedIn", "true");
    setIsLoggedIn(true);
  };

  const handleLoginFailure = (err) => {
    console.log(err);
  };

  if (!isLoggedIn) {
    return (
      // <div>
      //   <div className="vh-100 d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${backgroundImage2})`, backgroundSize: 'cover' }}>
      //     <div style={{ border: '5px solid #FF8C00', borderRadius: '0px', padding: '0px' }}>
      //       <LoginSocialGoogle
      //         client_id="803165020641-8cvk0a16u2quqdf7ku3s91v7dap6vekc.apps.googleusercontent.com"
      //         scope="openid profile email"
      //         discoveryDocs="claims_supported"
      //         access_type="offline"
      //         onResolve={handleLoginSuccess}
      //         onReject={handleLoginFailure}
      //       >
      //         <GoogleLoginButton />
      //       </LoginSocialGoogle>
      //     </div>
      //   </div>
      // </div>
      <div className="min-h-screen flex items-center justify-center adminManiBackground ">
        <div className="adminImgae rounded-lg shadow-lg w-full max-w-[800px] !mx-3 md:!mx-10">
          <div className="!bg-[#00000077] py-20 px-8 lg:px-44 rounded-lg shadow-lg w-full max-w-[800px] ">
            <h2
              className="text-4xl text-white text-center mb-14"
            // style={{ textShadow: '2px 4px 4px black' }}
            >
              ADMIN LOGIN
            </h2>
            <form>
              <div className="mb-2">
                <label className="block text-white text-lg mb-2" htmlFor="username" >
                  Username
                </label>
                <div className="bg-[#ffffffbd] rounded">
                  <input
                    className="placeholder:text-black rounded w-full py-2 px-3 leading-tight  font-semibold outline-none bg-transparent"
                    id="username"
                    type="text"
                    placeholder="admin"
                  />
                </div>
              </div>
              <div className="mb-6">
                <label className="block text-white text-lg mb-2" htmlFor="password" >
                  Password
                </label>
                <div className="bg-[#ffffffbd] rounded">
                  <input
                    className="placeholder:text-black rounded w-full py-2 px-3 leading-tight font-semibold outline-none bg-transparent"
                    id="password"
                    type="password"
                    placeholder="**********"
                  />
                </div>
              </div>
              <div className="flex items-center justify-center pb-4">
                <button
                  className="w-full bg-[#9B5BA0] hover:bg-[#48264A] text-white font-semibold text-lg py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="button"
                >
                  Login
                </button>
              </div>
              <div className="">
                <div >
                  <LoginSocialGoogle
                    client_id="803165020641-8cvk0a16u2quqdf7ku3s91v7dap6vekc.apps.googleusercontent.com"
                    scope="openid profile email"
                    discoveryDocs="claims_supported"
                    access_type="offline"
                    onResolve={handleLoginSuccess}
                    onReject={handleLoginFailure}
                  >
                    <GoogleLoginButton />
                  </LoginSocialGoogle>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Router>
      <div className="App">
        <div className="container-fluid">
          <div className="row">
            <Header />
            <div className="">
              <Sidebar logo={logo} backgroundImage={backgroundImage} />
            </div>
            <div className="py-10 md:py-20 custom-padding">
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/reviews" element={<AllReviews />} />
                <Route path="/menu" element={<Menu />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;