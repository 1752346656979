import React, { useState } from "react";

function Test() {
  const [isOpenDate, setIsOpenDate] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState("Select Date");
  const [selectedText, setSelectedText] = useState("Select Sort");
  const [expandedItems, setExpandedItems] = useState([]);
  const [reviewData, setReviewData] = useState({ reviews: [] });
  const [topReview, setTopReview] = useState({});

  const handleSelectDate = (date) => {
    setSelectedDate(date);
    setIsOpenDate(false);
  };

  const handleSelect = (sort) => {
    setSelectedText(sort);
    setIsOpen(false);
  };

  const loadDetails = (index, itemId) => {
    console.log(`Loading details for item ${itemId}`);
    const newExpandedItems = expandedItems.includes(index)
      ? expandedItems.filter((i) => i !== index)
      : [...expandedItems, index];
    setExpandedItems(newExpandedItems);
  };

  return (
    <div className="container ">
      <div className="row">
        <div className="col-12">
          <div className="d-flex !bg-[#9B5BA0] text-white flex-wrap !rounded-tr-lg !rounded-tl-lg py-2 ">
            <div className="col col-12 col-md-6 text-start ms-4">
              <h5 className="">Top Reviewed Items</h5>
            </div>
            <div className="col col-6 col-md-3 fs-6">
              {/* <label for="dropdownMenuDate" className=""> */}
              Date:
              {/* </label> */}
            </div>

            <div className="col col-6 col-md-3 fs-6 ">
              {/* <label htmlFor="dropdownMenu" className="me-2"> */}
              Sort By:
              {/* </label> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Test;
